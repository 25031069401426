import { useEffect, useRef, useState } from "react";
import goodsApi from "api/goodsApi";



export const useGoodsDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [goodsID, setGoodsID] = useState(0);
  const [goodsSubID, setGoodsSubID] = useState(0);
  const [favoriteID, setFavoriteID] = useState(0);
  const [goods, setGoods] = useState();

  // get goods details
  useEffect(() => {
    (async function getGoodsDetails() {
      if (!goodsID) return;
      try {
        setIsLoading(true);
        const { data } = await goodsApi.getGoodsDetails({ id: goodsID, goodsSubID });
        setGoods({ ...data.dtGoodsInfo[0] });
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, [goodsID, goodsSubID]);

  // Change Favorite
  useEffect(() => {
    (async function handleChangeFavorite() {
      if (!favoriteID || !goodsID) return;
      try {
        setIsLoading(true);
        /**Call Api */
        const { data } = await goodsApi.updateGoodsFavorite({
          goodsID,
          goodsSubID,
          favoriteID,
        });
        setGoods((preValue) => ({ ...preValue, FavoriteID: data?.favoriteID || 0 }))

      } catch (err) {
        alert(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [goodsID, favoriteID]);


  // Back from Goods Detials
  const handleBack = () => {
    setGoods({});
    setGoodsID(0);
    setFavoriteID(0);
  }

  // Return data 
  return {
    isLoading,
    goods,
    setGoodsID,
    setGoodsSubID,
    setFavoriteID,
    handleBack,
  }
}