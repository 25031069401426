import React from "react";
import { Button, Content, GoodsImg, Line, MLabel, Wrapper } from "./styles";
import GoodsImgDefault from "assets/images/goods_default.jpg";

const numFormat = (num) => {
  return new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 2 }).format(num);
}

const GoodsDetails = ({ goods, handleAddToCart, handleChangeFavorite, handleBack }) => {
  return <Wrapper>
    <Content>
      <Line>
        <MLabel>商品名：</MLabel>
        {goods.GoodsName || '―'}
      </Line>
      <Line>
        <MLabel>カナ：</MLabel>
        {goods.GoodsKana || '―'}
      </Line>
      <Line>
        <MLabel>サイズ：</MLabel>
        {goods.GoodsSize || '―'}
      </Line>
      <Line style={{ textAlign: "center" }}>
        <GoodsImg src={goods?.GoodsImageUrl || GoodsImgDefault} />
      </Line>
      {goods.VisiblePaintingInfoInApp && (
        <>
          <Line>
            <MLabel>塗布量：</MLabel>
            {numFormat(goods.TohuryouMin) + '～' + numFormat(goods.TohuryouMax) + ' 標準：' + numFormat(goods.TohuryouStandard) + ' kg/㎡/回'}
          </Line>
          <Line>
            <MLabel>メモ：</MLabel>
            {goods.TohuryouMemo || '―'}
          </Line>
          <Line>
            <MLabel>希釈：</MLabel>
            {goods.DilutionSolution} | {goods.DilutionPercent}
          </Line>
        </>
      )}
      <Line>
        <MLabel>製品資料：</MLabel>
        {goods.CatalogueUrl ? (<a href={goods.CatalogueUrl} target="_blank" rel="noreferrer"  >カタログを開く</a>) : '―'}
      </Line>

      <Line style={{ textAlign: "center", marginTop: "20px" }}>
        <Button style={{ maxWidth: "300px", width: "80%" }} onClick={() => { handleAddToCart(goods); }}>カートに追加</Button>
      </Line>
      <Line style={{ textAlign: "center", marginTop: "8px" }}>
        <Button style={{ maxWidth: "300px", width: "80%", backgroundColor: "#808289", borderColor: "#808289" }}
          onClick={() => { handleChangeFavorite(goods.GoodsID, goods.GoodsSubID || 1, goods.FavoriteID) }}> お気に入り{goods.FavoriteID > 0 ? "から削除" : "に追加"}</Button>
      </Line>
      <Line style={{ textAlign: "center", marginTop: "8px" }}>
        <Button style={{ maxWidth: "300px", width: "80%", backgroundColor: "#fff", borderColor: "#9c27b0", color: "#9c27b0" }}
          onClick={() => { handleBack(); }}>
          戻る
        </Button>
      </Line>
    </Content>
  </Wrapper >
};

export default GoodsDetails;
