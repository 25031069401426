import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-bottom:8px;
    .pl-30{
        padding-left: 30px;
    }

    .align-right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 2px;
        margin-right: 16px;
    }
`;

export const Title = styled.div`
    display: flex;
    background-color:#f0f0f0;
    padding: 4px 4px;
    font-weight: 600;
`

export const Line = styled.div`
    padding-left: 4px;
    padding-right: 4px;
    
`

export const MLabel = styled.label`
    padding: 0;
    color: #6c757d;
    font-size:0.9rem;
    font-weight: normal;
`

export const Button = styled.button`
    padding: 4px 12px;
    font-size: 1rem;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 3px;
    background-color: var(--morienGreen);
    color: var(--white);
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`