import React from "react";
import { PropTypes } from "prop-types";
import { Button, Content, Line, MLabel, Title, Wrapper, GoodsImg } from './styles';
import { SALESSLIP_STATUS, DELIVERY_TIME_ORDER } from "Common";
import dayjs from "dayjs";

import GoodsImgDefault from "assets/images/goods_default.jpg";

const OrderItem = ({ item, handleAddToCard, handleSelectGoods, handleClick }) => {
    return (
        <Wrapper>
            <div><GoodsImg onClick={() => {
                handleSelectGoods(item.GoodsID, item.GoodsSubID);
            }} src={item?.GoodsImageUrl ? item?.GoodsImageUrl : GoodsImgDefault} alt="image" /></div>
            <Content onClick={() => { handleClick(item.OrderTopID, item.SalesSlipID) }}>
                <Title>{item.GoodsName || '―'}</Title>
                <Line>
                    <MLabel>現場：</MLabel>
                    {item.SiteName || "-"}
                </Line>
                {!!item.OrderTopMemo && (<Line>
                    <MLabel>メモ：</MLabel>
                    {item.OrderTopMemo || ''}
                </Line>)}
                <Line>
                    <MLabel>配送日：</MLabel>
                    {item.DeliveryDate.length > 9 ? dayjs(item.DeliveryDate).format("M月DD日") + DELIVERY_TIME_ORDER[item?.DeliveryTimeID || 1] + SALESSLIP_STATUS[item.SalesSlipStatusID || 1] : '-'}
                </Line>
            </Content>
            <Button style={{ minWidth: "50px", position: "absolute", bottom: 4, right: 8 }} onClick={(e) => { handleAddToCard(item); }}>再注文</Button>
        </Wrapper>
    )
}

OrderItem.propTypes = {
    item: PropTypes.object.isRequired,
}

OrderItem.defaultProps = {
}


export default OrderItem;