import styled from 'styled-components';

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    display: none;

    :checked ~ #idOverlayPart {
        display: block;
        transform : translateX(0);
    }

    :checked ~ #idMenuPart {
        transform : translateX(0);
        opacity: 1;
    }
`
export const OverLay = styled.label.attrs({ id: 'idOverlayPart' })`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transform : translateX(-110%);
    animation: fadeIn linear 0.2s;
    z-index: 90;
    @keyframes fadeIn {
        from { opacity: 0;}
        to { opacity: 1;}
    }
`;

export const Menu = styled.nav.attrs({ id: 'idMenuPart' })`  
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 70%;
    max-width: 500px;
    background-color: #fff;
    transform : translateX(-110%);
    opacity: 0;
    transition: transform linear 0.2s, opacity linear 0.2s;
    z-index: 91;
    font-size: 1.2rem;
    .button {
        display: flex;
        justify-content: center;
        align-items: center;   
        padding: 8px;           
        cursor: pointer;
        :active {
            color: #fff;
            background-color: var(--morienGreen);
        }
    }

    .icon{
        font-size: 30px;        
    }
    

`;

export const HeaderMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ececec;
    border-bottom: 2px solid var(--morienGreen);
`;

export const MenuClose = styled.label`
    height: 100%;
    
`;

export const BodyMenu = styled.ul`
    flex-grow: 1;
    list-style-type: none;
    padding: 0;
    margin:0;

    .menu-item{
        color: #007bff;
        text-decoration:none;
        padding: 13px 10px;
        display:block;
        font-weight: 600;
        height: 100%;
        border-bottom: 1px solid #dedede;
    }

    .active{        
        background-color:#007bff;
        color: var(--white);
    }
`

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: between;
    flex:1;
    padding:  6px 0 0 5px;
    /* cursor: pointer;
    :active {
        color: #fff;
        background-color: var(--morienGreen);
    } */
`;


export const ClientName = styled.label`
    flex-grow: 1;
    font-size: 0.7rem;
`;

export const UserInfo = styled.label`
    flex-grow: 1;
    font-size: 1%.1; 
    font-weight: bold;   
`;


export const BottomMenu = styled.button`
    height:2.5rem;
    margin: 0;
    border:1px solid transparent;
    background-color: #ddd;    
    font-size: 1rem;
    color: #007bff;
`