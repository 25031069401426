import axiosClient from "./axiosClient";

const logoutApi = {
    logout() {
        const url = '/logout';
        return axiosClient.post(url, {});
    }
}

export default logoutApi
