import React from "react";
import { MLabel, Wrapper } from './styles'

const SiteItem = ({ item, onSelectSite }) => {
    return (
        <Wrapper onClick={() => {
            onSelectSite(item.SiteID);
        }}>
            <span className='title'>{item.SiteName || '―'}</span>
            <span ><MLabel>住所：</MLabel>〒{item.PostalCode || '―'}　{item.AddressPrefecture || ''}{item.AddressCity || ''}{item.AddressNumber || ''}</span>
            {/* <div>
                <span ><MLabel>開始日：</MLabel>{item.DateBeginning ? item.DateBeginning.slice(0, 10) : '－'}</span>
                <span className='ml-30'><MLabel>最後：</MLabel>{item.DateFinished ? item.DateFinished.slice(0, 10) : '－'}</span>
            </div> */}
        </Wrapper>
    )
}

export default SiteItem;