import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cart, GoodsNum, HeaderTitle, Label, Wrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import NavMenu from '../NavMenu';
import CartGoods from '../CartGoods';
import Common from '../../Common';

const { userName, clientName } = Common.localStorageGetUserInfo();

const HeaderBox = ({ title, haveCart }) => {

    const cart = useSelector((state) => state.cart);
    let goodsTotal = cart?.selectedList?.length || 0;
    useEffect(() => {
        document.title = `株式会社モリエン｜${title}`;
    }, [title]);

    return (
        <Wrapper>
            <NavMenu idMenu="id_menu" userName={userName} clientName={clientName} />
            <Label htmlFor='id_menu' className='button icon borderRight ' >
                <FontAwesomeIcon icon={faBars} />
            </Label>

            <HeaderTitle>{title}</HeaderTitle>

            {haveCart &&
                <>
                    <Cart className='button icon borderLeft' htmlFor='idCartGoods'>
                        <FontAwesomeIcon icon={faCartShopping} />
                        {goodsTotal === 0 ? null : <GoodsNum>{goodsTotal}</GoodsNum>}
                    </Cart>
                    <CartGoods idCartGoods={"idCartGoods"} />
                </>
            }
        </Wrapper>
    )
}

HeaderBox.propTypes = {
    title: PropTypes.string,
    haveCart: PropTypes.bool,
}

HeaderBox.defaultProps = {
    title: '現場名',
    haveCart: false
}

export default HeaderBox
