import { createSlice } from '@reduxjs/toolkit'
import Common from '../../Common';

const initialState = Common.localStorageGeCartInfo();

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addGoods: (state, actions) => {
            const { goods, quantity } = actions.payload;
            const selectedList = state?.selectedList || [];

            const item = {
                goods,
                quantity
            };

            //カートに商品検索
            let index = -1;
            if (!goods.ColorBookID || !goods.ColorNoID || !goods.ColorLevelID) {
                //一般商品
                index = selectedList.findIndex((item) =>
                ((item.goods.GoodsName === goods.GoodsName)
                    && (item.goods.GoodsID === goods.GoodsID)
                    && (item.goods.GoodsSubID === goods.GoodsSubID)));
            } else {
                //商品調色
                index = selectedList.findIndex((item) => {
                    if (!item.goods?.ColorBookID || !item.goods?.ColorNoID || !item.goods?.ColorLevelID) return false;
                    return ((item.goods.GoodsID === goods.GoodsID)
                        && (item.goods.GoodsSubID === goods.GoodsSubID)
                        && (item.goods.ColorBookID === goods.ColorBookID)
                        && (item.goods.ColorNoID === goods.ColorNoID)
                        && (item.goods.ColorLevelID === goods.ColorLevelID));
                });
            }

            if (index >= 0) {
                item.quantity += selectedList[index].quantity;
                selectedList[index] = { ...item };
            } else {
                state.selectedList = [...selectedList, { ...item }];
            }
            Common.localStorageSetCartInfo(state);
        },
        removeGoods: (state, actions) => {
            const { goods } = actions.payload;
            const selectedList = state?.selectedList || [];

            let index = -1;
            if (!goods.ColorBookID || !goods.ColorNoID || !goods.ColorLevelID) {
                //一般商品
                index = selectedList.findIndex((item) =>
                ((item.goods.GoodsName === goods.GoodsName)
                    && (item.goods.GoodsID === goods.GoodsID)
                    && (item.goods.GoodsSubID === goods.GoodsSubID)));
            } else {
                //商品調色
                index = selectedList.findIndex((item) => {
                    if (!item.goods?.ColorBookID || !item.goods?.ColorNoID || !item.goods?.ColorLevelID) return false;
                    return ((item.goods.GoodsID === goods.GoodsID)
                        && (item.goods.GoodsSubID === goods.GoodsSubID)
                        && (item.goods.ColorBookID === goods.ColorBookID)
                        && (item.goods.ColorNoID === goods.ColorNoID)
                        && (item.goods.ColorLevelID === goods.ColorLevelID));
                });
            }
            state.selectedList.splice(index, 1);
            Common.localStorageSetCartInfo(state);
        },

        // 配送タイプ
        setDeliveryTypeID: (state, actions) => {
            state.deliveryTypeID = actions.payload;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },
        removeDeliveryTypeID: (state) => {
            state.deliveryTypeID = 1;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        // 配送タイプ
        setBase: (state, actions) => {
            state.baseID = actions.payload;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },
        removeBase: (state) => {
            state.baseID = 1;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        // 現場
        setSite: (state, actions) => {
            const site = actions.payload;
            state.siteID = site.siteID;
            state.siteName = site.siteName;
            state.siteAddr = site.siteAddr;
            state.siteTypeID = site.siteTypeID;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        removeSite: (state) => {
            state.siteID = "";
            state.siteName = '';
            state.siteAddr = '';
            state.siteTypeID = 0;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        // 配送希望
        setDeliveryDate: (state, actions) => {
            const deliveryDate = actions.payload;
            state.deliveryDate = deliveryDate;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        // 配達希望時
        setDeliveryTimeID: (state, actions) => {
            const deliveryTimeID = actions.payload;
            state.deliveryTimeID = deliveryTimeID;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },

        // 配達希望時
        setMemo: (state, actions) => {
            const memo = actions.payload;
            state.memo = memo;
            //save cart to localstorage
            Common.localStorageSetCartInfo(state);
        },
        removeCart: (state) => {
            state = {};
            Common.localStorageRemoveCartInfo();
        },
    },
})

// Action creators are generated for each case reducer function
export const { addGoods, removeGoods, setDeliveryTypeID, setBase, setSite, removeSite, setDeliveryDate, setDeliveryTimeID, setMemo, removeCart } = cartSlice.actions;

export default cartSlice.reducer