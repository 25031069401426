import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import siteApi from "../../api/siteApi";

export const useSiteNew = () => {
    const [siteInfo, setSiteInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRun = useRef(true);

    const navigate = useNavigate();


    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        const registerSite = async () => {
            try {
                //validate
                if (!siteInfo?.siteName || !siteInfo?.dateBeginning || !siteInfo?.dateFinished) return;

                setIsLoading(true);
                const dtResponse = await siteApi.registerSite(siteInfo);
                //TODO: New Site
                const newSiteID = dtResponse?.data['siteID'] ?? '';
                if (newSiteID < 1) {
                    throw Error("登録が失敗しました。");
                }

                alert("登録が成功しました。");
                navigate(`/site`, { replace: true });
            } catch (err) {
                alert(err);
                setIsLoading(false);
            }
        }
        registerSite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteInfo])

    return { setSiteInfo, isLoading };

}