import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color:beige;
    
`;

export const ContentTextWrapper = styled.div`
    text-align: center;
    padding: 0 5px 3px 5px;
    font-weight: 600;
`

export const TermBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 8px 0 8px;
    .title-text {
        font-size:0.9rem;
        background-color: #ccc;  
    }  
    .flex-wrap {
        flex-wrap: wrap;
    } 
`

export const TermLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem; 
    margin-top: 2px;
`

export const MLabel = styled.label`
    padding: 0 5px;
    color: #6c757d;
    font-size: 0.9rem;
`

export const TermSelect = styled.select`
    /* padding: 0 4px; */
    border-radius: 5px;
    font-size: 1rem;    
    color: black;     
`
export const TermOption = styled.option`
    font-size: 1rem;    
`

export const TermInputDate = styled.input.attrs({ type: 'date' })`
    width: 116px;
    font-size: 1rem;
    padding: 0;
    margin: 0 2px;
    color: black;
`