import React from "react";
import { Wrapper } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

const ButtonBack = ({ onHandleBack }) => {
  return <Wrapper onClick={() => { onHandleBack(); }}>
    <FontAwesomeIcon icon={faShare} flip="horizontal" />
  </Wrapper>;
};

export default ButtonBack;
