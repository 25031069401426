import { useEffect, useRef, useState } from 'react';
import loginApi from 'api/loginApi';
import Common from 'Common'

const initLoginInfo = {
    userName: '',
    password: ''
}
export const useLogin = () => {
    const [loginInfo, setLoginInfo] = useState(initLoginInfo);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstLoad = useRef(true);

    useEffect(() => {

        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
        }


        (async function login() {
            // const position = await Common.getPosition();
            const position = { Latitude: "", Longitude: "" };
            const loginUser = {
                userLogin: loginInfo.userName,
                password: loginInfo.password + Common.PW_TAIL,
                ...position
            }

            try {
                setIsLoading(true);
                /**Call Api */
                const dtResponse = await loginApi.login(loginUser);

                let cUserName = dtResponse?.data['cUser'] ?? "";
                let cToken = dtResponse?.data['cToken'] ?? "";
                let cClientName = dtResponse?.data['cClientName'] ?? "";
                let cDivision = dtResponse?.data['cDivision'] ?? "";
                if (cUserName && cToken) {
                    Common.localStorageSetToken(cUserName, cToken, cClientName, cDivision);
                    window.location.replace(Common.URL_HOME);
                } else {
                    //TODO: Login ERROR
                    console.log(dtResponse);
                    throw new Error('ユーザーが存在しません。');
                }
            } catch (err) {
                alert('ログインに失敗しました！');
                setIsLoading(false);
            }
        })();
    }, [loginInfo])

    return { setLoginInfo, isLoading };
}