import React from "react";
import { useDispatch } from "react-redux";
import { removeSite, setBase, setDeliveryDate, setDeliveryTimeID, setDeliveryTypeID, setMemo, setSite } from "../cartSlice";
import Footer from "../components/Footer";
import InputSite from "../components/InputSite";
import { InputDate, MLabel, Option, Row, Select, TextArea, Wrapper } from "./styles";
import { DELIVERY_TYPE, BASE_LIST, DELIVERY_TIME } from "Common";


const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const dNext = new Date();
dNext.setDate(dNow.getDate() + 1);
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;

const DeliveryInfo = ({ cart, setNextStep, handleBack, division }) => {

  const dispatch = useDispatch();

  const handleDeliveryTypeChange = (e) => {
    dispatch(removeSite());
    dispatch(setDeliveryTypeID(Number(e.target.value)));
  }

  const handleBaseChange = (e) => {
    const baseID = Number(e.target.value);
    //拠点ID保存
    dispatch(setBase(baseID));
  }

  const handleSiteChange = (siteInfo) => {

    const site = {
      siteID: siteInfo?.SiteID,
      siteName: siteInfo?.SiteName,
      siteAddr: `〒${siteInfo?.PostalCode || ""} ${siteInfo?.AddressPrefecture || ""}${siteInfo?.AddressCity || ""}${siteInfo?.AddressNumber || ""}`,
      siteTypeID: siteInfo?.SiteTypeID,
    }
    dispatch(setSite(site));
  }

  const handleDeliveryDateChange = (e) => {
    dispatch(setDeliveryDate(e.target.value));
  }

  const handleDeliveryTimeIDChange = (e) => {
    dispatch(setDeliveryTimeID(Number(e.target.value)));
  }

  const handleMemoChange = (e) => {
    dispatch(setMemo(e.target.value));
  }

  return (
    <Wrapper>
      {/* 配達タイプ */}
      <Row column hidden={!!division}>
        <MLabel>配達タイプ：</MLabel>
        <Select
          value={cart?.deliveryTypeID || 2}
          onChange={(e) => { handleDeliveryTypeChange(e); }}

        >
          {DELIVERY_TYPE.map((item) => {
            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
          })}
        </Select>
      </Row>

      {/* 拠点: deliveryType=引取り */}
      {cart?.deliveryTypeID === 5 ? (<>
        <Row column hidden={!!division}>
          <MLabel>拠点：</MLabel>
          <div style={{ display: 'flex' }}>
            <Select
              value={cart?.baseID || 1}
              onChange={handleBaseChange}
            >
              {BASE_LIST.map((item) => {
                return (<Option key={item.id} value={item.id}>{item.name}</Option>)
              })}
            </Select>
            <span className='sub-label'> ※引取りをご希望の場合、引取り希望の拠点を選択お願い致します。</span>
          </div>
        </Row>
      </>) : null}


      {/* 現場｜御店｜倉庫　選択 */}
      {/* 2-現場届け | 3-店届け  | 4-倉庫届け 　→ 現場表示*/}
      {[2, 3, 4].includes(Number(cart?.deliveryTypeID || 2)) ?
        <Row column hidden={!!division}>
          <InputSite selectSite={handleSiteChange} siteName={cart?.siteName || ""} deliveryTypeID={Number(cart?.deliveryTypeID || 1)} />
        </Row>
        : null}

      {/* 配送希望 */}
      <Row column>
        <MLabel>配達希望日：</MLabel>
        <InputDate
          min={dNowStr}
          max={'2099-12-31'}
          value={cart?.deliveryDate || dNextStr}
          onChange={e => handleDeliveryDateChange(e)}
        />
      </Row>

      {/* 配達希望時 */}
      <Row column>
        <MLabel>配達希望時：</MLabel>
        <Select
          value={cart.deliveryTimeID}
          onChange={e => handleDeliveryTimeIDChange(e)}
        >
          {DELIVERY_TIME.map((item) => {
            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
          })}
        </Select>
      </Row>

      {/* メモ */}
      <Row column>
        <MLabel>メモ：</MLabel>
        <TextArea
          maxLength={100}
          rows={4}
          value={cart.memo}
          onChange={e => handleMemoChange(e)} />
      </Row>

      <Footer handleNext={() => { setNextStep(prevValue => (prevValue + 1)); }} handleBack={handleBack} textNext={"注文"} />
    </Wrapper>
  );
};

export default DeliveryInfo;
