import styled from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    height: calc(100vh - 140px);
    overflow-y:scroll;
    padding-bottom: 100px;
`;

export const DataEmpty = styled.div`
    text-align:center;
    font-size: 1rem;
    font-weight: 500;    
`;