import axiosClient from "./axiosClient";

const siteApi = {

    getSite(params) {
        const url = '/site';
        return axiosClient.get(url, { params: params });
    },
    getSiteDetails(params) {
        const url = '/site/details';
        return axiosClient.get(url, { params: params });
    },
    registerSite(params) {
        const url = '/site/register';
        return axiosClient.post(url, params);
    },
}

export default siteApi;
