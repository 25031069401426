import axiosClient from "./axiosClient";

const imgPostApi = {
    post(params) {
        const url = '/img-post';
        return axiosClient.post(url, params);
    },
}

export default imgPostApi;
