import React from "react";
import CartItem from "components/CartGoods/CartItem";
import Footer from "../components/Footer";

const GoodsList = ({ goodsList, setNextStep, handleBack }) => {

  return (
    <>
      {goodsList?.length > 0 ? (
        goodsList.map((item, index) => {
          return (<CartItem key={index} item={item} />)
        })
      ) : (<div>データなし</div>)}
      <Footer handleNext={() => { if (goodsList?.length > 0) setNextStep(prevValue => (prevValue + 1)); }} handleBack={handleBack} />
    </>
  );
};

export default GoodsList;
