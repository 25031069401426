import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ececec;   
    position: relative;
`;

export const Content = styled.div`
    flex: 4;
    display:flex;
    flex-direction: column;
    
    padding: 5px 5px;
    color: var(--black);
    text-decoration:none;
    .ml-30 {
        margin-left: 30px;
    }
    :hover{
        background-color: #ececec;
        cursor:pointer;
    }  

`
export const Title = styled.span`
    color:var(--morienGreen);
    font-weight:bold;
    font-size:var(--fontSMed);
`
export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`

export const TagStatus = styled.span`
    padding: 2px 5px 2px 5px;
    font-size: 0.9rem;
    border-radius: 5px;
    background-color: ${props => props.bgColor};
`

export const Button = styled.button`
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 3px;
    background-color: var(--morienGreen);
    color: var(--white);
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`

export const GoodsImg = styled.img`
    padding-top: 2px;
    padding-left: 2px;
    max-width: 44px;
`;