import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CheckBox, Menu, OverLay, MenuClose, HeaderMenu, UserInfo, BottomMenu, BodyMenu, ContainerInfo, ClientName } from './styles';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logoutApi from '../../api/logoutApi';
import Common from '../../Common';


const NavMenu = ({ idMenu, userName, clientName }) => {

    const [isLogout, setIsLogout] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const logout = async () => {
            try {
                await logoutApi.logout();

                Common.localStorageRemoveToken();
                navigate("/login");
            } catch (error) {
                console.log(error);

            }
        }

        if (isLogout) {
            logout();
        }
    }, [isLogout, navigate])

    return (
        <>
            <CheckBox id={idMenu} hidden />
            <OverLay htmlFor={idMenu} />
            <Menu>
                <HeaderMenu>
                    <ContainerInfo>
                        <UserInfo>{userName}</UserInfo>
                        <ClientName>{clientName}</ClientName>
                    </ContainerInfo>
                    <MenuClose htmlFor={idMenu} className='button icon'>
                        <FontAwesomeIcon icon={faTimes} />
                    </MenuClose>
                </HeaderMenu>
                <BodyMenu>
                    <li>
                        <NavLink to={'/goods'} className='menu-item' reloadDocument >新規注文・商品検索（お気入り）</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/goods-all'} className='menu-item' reloadDocument>新規注文・商品検索（全て）</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/site'} className='menu-item' >現場検索</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/order'} className='menu-item' >注文履歴</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/site-new'} className='menu-item' >現場登録</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/img-post'} className='menu-item' >写真投稿</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/user'} className='menu-item' >パスワード変更</NavLink>
                    </li>
                </BodyMenu>
                <BottomMenu onClick={() => { setIsLogout(true) }}>
                    ログアウト <FontAwesomeIcon icon={faRightFromBracket} />
                </BottomMenu>
            </Menu>
        </>
    )
}

NavMenu.propTypes = {
    idMenu: PropTypes.string,
    userName: PropTypes.string,
    clientName: PropTypes.string,
}

NavMenu.defaultProps = {
    idMenu: 'id',
    userName: 'スタッフ名前',
    clientName: 'お客様名'
}
export default NavMenu;
