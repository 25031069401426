import React, { useEffect, useRef, useState } from "react";
import siteApi from "api/siteApi";
import Common from "Common";
import InputSelectBox from "components/InputSelectBox";
import { Line, MLabel, Title } from "./styles";

export const SITE_TYPE = (deliveryTypeID) => {
  switch (Number(deliveryTypeID)) {
    case 2:
      return {
        id: 1,
        name: "現場",
      };
    case 3:
      return {
        id: 2,
        name: "御店",
      };
    case 4:
      return {
        id: 3,
        name: "倉庫",
      };
    default:
      return {
        id: 1,
        name: "現場",
      };
  }
}

const InputSite = ({ siteName, deliveryTypeID, selectSite }) => {
  const siteType = SITE_TYPE(deliveryTypeID);
  const [termSite, setTermSite] = useState({ txtSearch: '', siteTypeID: siteType.id });
  const [siteList, setSiteList] = useState([]);
  //skip first load (init render)
  const firstLoad = useRef(true);

  useEffect(() => {
    setTermSite(prevValue => ({ ...prevValue, siteTypeID: siteType.id }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTypeID])

  useEffect(() => {
    const searchSite = async () => {
      try {
        //TODO: Loading
        // setIsLoading(true);

        /**Call Api */
        const params = {
          siteTypeID: siteType.id,
          siteDateID: Number(siteType?.id || 0) === 1 ? termSite?.siteDateID : "",
          dateFrom: Number(siteType?.id || 0) === 1 ? termSite?.dateFrom : "",
          dateTo: Number(siteType?.id || 0) === 1 ? termSite?.dateTo : "",
          txtSearch: termSite?.txtSearch || '',
        }

        const dtResponse = await siteApi.getSite(params);
        const dtSite = dtResponse?.data['dtSite'] ?? [];

        setSiteList(dtSite);
        // setIsLoading(false);

      } catch (err) {
        alert(err);
        // setIsLoading(false);
      }
    }

    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    searchSite();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termSite, termSite.txtSearch, termSite.dateFrom, termSite.dateTo, termSite.siteDateID])

  const renderSiteItem = (item) => {
    return (
      <>
        <Title >{item.SiteName}</Title>
        <Line>
          <MLabel>住所：</MLabel>
          〒{item.PostalCode}　{item.AddressPrefecture || ''}{item.AddressCity || ''}{item.AddressNumber || ''}
        </Line>
        <Line>
          <MLabel>日付：</MLabel>{item.DateBeginning ? item.DateBeginning.slice(0, 10) : '－'}～{item.DateFinished ? item.DateFinished.slice(0, 10) : '－'}
        </Line>
      </>)
  }

  return (
    <>
      <MLabel>{siteType.name}：</MLabel>
      <InputSelectBox title={`${siteType.name}を選択してください`}
        name={siteName}
        textHolder={`${siteType.name}名`}
        termSearch={termSite}
        setTermSearch={setTermSite}
        termBoxType={Common.TERMBOX_SITE}
        resultList={siteList}
        renderItem={renderSiteItem}
        selectItem={selectSite}
        classNameCss={'pl-20'}
      ></InputSelectBox>
    </>);
};

export default InputSite;
