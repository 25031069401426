import React, { useState } from "react";
import { TermBoxWrapper, TermLine, TermSelect, TermOption, TermInputDate } from './styles';

const dNow = new Date();
const dNext = new Date();
dNext.setMonth(dNow.getMonth() + 1);
dNow.setMonth(dNow.getMonth() - 1);

const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;
// TERMBOX_SITE
const SiteDate = [
  { id: 0, name: '期間なし' },
  { id: 1, name: '開始日' },
  { id: 2, name: '最終日' },
]
const SiteType = [
  { id: 1, name: '一般現場' },
  { id: 2, name: '御店' },
  { id: 3, name: '倉庫' },
]

export const TermSite = ({ setTermSearch }) => {
  const [siteTypeID, setSiteTypeID] = useState(1);
  const [siteDateID, setSiteDateID] = useState(0);
  const [dateFrom, setDateFrom] = useState(dNowStr);
  const [dateTo, setDateTo] = useState(dNextStr);




  return <TermBoxWrapper>
    <TermLine className="flex-wrap">
      <TermSelect className="title-text" value={siteTypeID} onChange={(e) => {
        const num = Number(e.target.value || 1);
        setSiteTypeID(num);
        setTermSearch((preValue) => ({ ...preValue, siteTypeID: num }))
      }}>
        {SiteType.map((item) => {
          return (<TermOption key={item.id} value={item.id}>{item.name}</TermOption>);
        })}
      </TermSelect>
    </TermLine>
    {siteTypeID === 1 && (<>
      <TermLine className="flex-wrap">
        <TermSelect className="title-text" value={siteDateID} onChange={(e) => {
          const num = Number(e.target.value || 1);
          setSiteDateID(num);
          setTermSearch((preValue) => {
            return siteTypeID === 1 ? { ...preValue, siteDateID: num, dateFrom, dateTo } : { ...preValue, siteDateID: num };
          })
        }}>
          {SiteDate.map((item) => {
            return (<TermOption key={item.id} value={item.id}>{item.name}</TermOption>);
          })}
        </TermSelect>
        {(siteDateID > 0) && (<>
          ：
          <TermInputDate
            value={dateFrom}
            onChange={(e) => {
              setDateFrom(e.target.value);
              if (siteDateID > 0) setTermSearch((preValue) => ({ ...preValue, dateFrom: e.target.value }))
            }}
          />
          ～<TermInputDate
            value={dateTo}
            onChange={(e) => {
              setDateTo(e.target.value);
              if (siteDateID > 0) setTermSearch((preValue) => ({ ...preValue, dateTo: e.target.value }))
            }}
          /></>)}
      </TermLine>
    </>)}
  </TermBoxWrapper>;
};
