import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom:32px;
    position: absolute;
    top: 0;
    left:0;   
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 2;
    background: white;
`;

export const Content = styled.div`
    /* width:100%; */
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
    font-size: 1rem;
    padding: 8px 0;

    .ml-30 {
        margin-left: 30px;
    }
`;

export const Line = styled.div`
    padding-left: 5px;
    padding-right: 5px;
`

export const MLabel = styled.label`
    padding: 0 5px;
    color: #6c757d;
`
export const SHeader = styled.div`
    padding: 3px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:600;
`;