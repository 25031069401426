import axiosClient from "./axiosClient";

const loginApi = {
    login(params) {
        const url = '/login';
        return axiosClient.post(url, params);
    },
    getUserInfo() {
        const url = '/user';
        return axiosClient.get(url, {});
    },
    changePass(params) {
        const url = '/password';
        return axiosClient.post(url, params);
    }
}

export default loginApi
