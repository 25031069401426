import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100vw;
`;

export const HeaderTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 4px;
    background-color: #eee;
    border-bottom: 1px solid #ccc; 
    font-weight:700;
    font-size: 1.2rem;
`;

export const Content = styled.div`
    height: calc(100vh - 140px);
    overflow-y:scroll;
    padding-bottom: 100px;
`;

export const WraperDetails = styled.div`
    position: absolute;
    top: 0;
    left:0;   
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 10;
    background: white;
`;
