import React, { useEffect, useState } from "react";
import loginApi from "../../api/loginApi";
import logoutApi from "../../api/logoutApi";
import Common from "../../Common";
import HeaderBox from "../../components/HeaderBox";
import Loading from "../../components/Loading";
import { Wrapper, Content, Input, Row, MLabel, Button, Footer, Line } from './styles'

const UserInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassAgain, setNewPassAgain] = useState('');
  const [save, setSave] = useState(false);
  const [userID, setUserID] = useState('');
  const [clientName, setClientName] = useState('');


  useEffect(() => {
    (async function getUserInfo() {
      try {
        setIsLoading(true);

        const { data } = await loginApi.getUserInfo();
        setUserID(data?.userLogin || '');
        setClientName(data?.clientName || '');
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  useEffect(() => {
    if (!save) return;

    const validate = () => {
      let msg = '';
      if (oldPass.length < 6 || newPass.length < 6) {
        msg = "パスワードは文字以上、20文字以内で入力してください。";
      } else if (newPass !== newPassAgain) {
        msg = "新パスワードと再入力パスワードが一致しません。";
      }
      return msg;
    }

    (async function changePassWord() {
      const msg = validate();
      if (msg) {
        alert(msg);
        setSave(false);
        return;
      }

      try {
        setIsLoading(true);
        const params = {
          password: oldPass.trim() + Common.PW_TAIL,
          newPassword: newPass.trim()
        }
        await loginApi.changePass(params);
        alert("パスワードを変更しました。");

        await logoutApi.logout();

        Common.localStorageRemoveToken();
        setIsLoading(false);
        window.location.replace(Common.URL_HOME);

      } catch (error) {
        alert(error);
        setIsLoading(false);
      } finally {
        setSave(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save])

  return <Wrapper>
    <Loading isLoading={isLoading} />
    <HeaderBox title="ユーザー情報" haveCart={true} />
    <Content>
      <Row column>
        <Line>
          <MLabel>ユーザーID：</MLabel>
          {userID}
        </Line>
      </Row>
      <Row column>
        <Line>
          <MLabel>お客様名：</MLabel>
          {clientName}
        </Line>
      </Row>
      <Row column>
        <MLabel forHtml={'old_pass'}>現在パスワード：</MLabel>
        <div style={{ textAlign: 'center' }}>
          <Input required
            minLength={6}
            maxLength={50}
            name="old_pass"
            id="old_pass"
            type='password'
            width={'300px'}
            value={oldPass}
            onChange={(e) => { setOldPass(e.target.value) }}
          />
        </div>
      </Row>
      <Row column>
        <MLabel forHtml={'new_pass'}>新パスワード：</MLabel>
        <div style={{ textAlign: 'center' }}>
          <Input required
            minLength={6}
            maxLength={50}
            name='new_pass'
            id='new_pass'
            type='password'
            width={'300px'}
            value={newPass}
            onChange={(e) => { setNewPass(e.target.value) }}
          />
        </div>
      </Row>
      <Row column>
        <MLabel forHtml='new_pass_again'>再入力パスワード：</MLabel>
        <div style={{ textAlign: 'center' }}>
          <Input required
            minLength={6}
            maxLength={50}
            name="new_pass_again"
            id="new_pass_again"
            type='password'
            width={'300px'}
            value={newPassAgain}
            onChange={(e) => { setNewPassAgain(e.target.value) }}
          />
        </div>
      </Row>
      <Footer>
        <Button onClick={() => { setSave(true) }}>変更</Button>
      </Footer>
    </Content>
  </Wrapper>
};

export default UserInfo;
