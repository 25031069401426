import axios from 'axios';
import Common from '../Common';

const axiosClient = axios.create({
    baseURL: Common.API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

// Interceptors
// Add a request interceptor
axiosClient.interceptors.request.use(
    async function (config) {
        // const { latitude, longitude } = await Common.getPosition();
        const { latitude, longitude } = ["", ""];
        const { cUserName, cToken } = Common.localStorageGetToken();
        config.headers['C-User'] = cUserName;
        config.headers['C-Token'] = cToken;
        config.headers['Latitude'] = latitude;
        config.headers['Longitude'] = longitude;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        //TODO: Catch Error
        console.log('エラー：' + error.message);
        console.log(error.response);
        if (error.response?.status === 401) {
            Common.localStorageRemoveToken();
            window.location.replace(Common.URL_LOGIN);
        }
        return Promise.reject(error);
    }
);

export default axiosClient
