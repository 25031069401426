import styled from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 5px 5px;
    border-bottom: 2px solid #ececec;
    color: var(--black);
    text-decoration:none;
    .title {
        color:var(--morienGreen);
        font-weight:bold;
        font-size:var(--fontSMed);
    }
    .ml-30 {
        margin-left: 30px;
    }

    :hover{
        background-color: #ececec;
        cursor:pointer;
    }
`;

export const MLabel = styled.small`
    color: #6c757d;
`