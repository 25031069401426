import React from "react";
import { Button, Wrapper } from "./styled";

const Footer = ({ handleBack, handleNext, textNext = "次に" }) => {
  return (
    <Wrapper>
      <Button className='back' onClick={() => { handleBack(); }}>戻る</Button>
      <Button onClick={() => { handleNext(); }}>{textNext}</Button>
    </Wrapper>);
};

export default Footer;
