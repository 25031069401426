import styled from 'styled-components';

export const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    animation: fadeIn linear 0.2s;
`;
export const WrapperIcon = styled.div`
    text-align: right;
    padding: 4px 16px 0 0;
`;

export const Wrapper = styled.div`
    background-color: #fff;
    z-index: 2;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 60px;
    animation:  fadeIn linear 0.2s, TransformRight linear 0.2s;  
`;

export const Title = styled.div`
    color:var(--morienGreen);
    font-weight:bold;
    font-size:var(--fontSMed);
    border-bottom: 2px solid rgba(0,0,0,0.3);
    padding-top: 8px;
    padding-left: 8px;
`;

export const TermInputDate = styled.input.attrs({ type: 'date' })`
    width: 116px;
    font-size: 1rem;
    padding: 0;
    margin: 0 2px;
    color: black;
`

export const WrapperTerm = styled.div`
    padding: 8px;
`;

export const LineTerm = styled.div`
    margin-top: 8px;
`;

export const BtnSearch = styled.div`
    width: 70%;
    margin: 20px 0;
    padding: 8px;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 8px;
    background-color: var(--morienGreen);
    color: var(--white);
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
    text-align: center;
`;