import { useEffect, useRef, useState } from "react";
import goodsApi from "api/goodsApi";



export const useMorienShopSearch = () => {
  const [termSearch, setTermSearch] = useState();
  const [goodsList, setGoodsList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const refTop = useRef();

  //Search Goods
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          txtSearch: termSearch?.txtSearch || '',
        }
        const dtResponse = await goodsApi.morienShopSearch(params);
        const dtGoods = dtResponse?.data['dtGoods'] ?? [];
        setGoodsList([...dtGoods]);
        if (refTop) refTop.current.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [termSearch])


  // Return data 
  return {
    refTop,
    termSearch,
    setTermSearch,
    goodsList,
    isLoading
  }
}