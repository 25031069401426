import React from "react";
import {
    Content,
    GoodsImg,
    Line,
    MLabel,
    Title,
    Wrapper,
    WrapperContent,
} from './styles'

import GoodsImgDefault from "assets/images/goods_default.jpg";


const ShopGoodsItem = ({ goods }) => {

    return (
        <Wrapper >
            <WrapperContent>
                {goods?.GoodsParentName ? (<></>) : (
                    <div>
                        <GoodsImg src={goods?.GoodsImageUrl ? goods?.GoodsImageUrl : GoodsImgDefault} alt="image" />
                    </div>)}
                <Content>
                    <Title>{goods.GoodsName || '-'}</Title>
                    <Line>
                        <MLabel>サイズ：</MLabel>
                        {goods.GoodsSize || '―'}
                    </Line>
                </Content>
            </WrapperContent>
        </Wrapper>
    )
}

export default ShopGoodsItem;