import React, { useEffect, useRef } from "react";
import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import SiteItem from "./SiteItem";
import { Wrapper, Content, DataEmpty } from './styles'
import { useSite } from "./useSite";
import SearchBox from "components/SearchBox";
import { TermSite } from "components/TermSearch";
import { useSiteDetails } from "./useSiteDetails";
import SiteDetails from "./SiteDetails";
import ButtonBack from "components/ButtonBack";

const Site = () => {
    const { setTermSearch, siteList, isLoading } = useSite();
    const { isLoading: isLoading1, handleSelectSite, handleBackSiteList, objSite, salesGoodsList, totalPrice } = useSiteDetails();

    const refTop = useRef();

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [siteList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading || isLoading1} />
            <HeaderBox title={objSite.SiteID > 0 ? "現場明細" : "現場検索"} haveCart />
            <div style={{ position: "relative" }}>
                <SearchBox setTermSearch={setTermSearch} placeholder={"現場名"}>
                    <TermSite setTermSearch={setTermSearch} />
                </SearchBox>
                <Content ref={refTop}>
                    {
                        siteList.length > 0 ? siteList.map((item) => {
                            return (
                                <SiteItem key={item.SiteID} item={item} onSelectSite={handleSelectSite} />
                            );
                        }) : <DataEmpty>データなし</DataEmpty>
                    }
                </Content>
                {objSite?.SiteID > 0 && <SiteDetails objSite={objSite} totalPrice={totalPrice} salesGoodsList={salesGoodsList} />}
                {objSite?.SiteID > 0 && <ButtonBack onHandleBack={handleBackSiteList} />}

            </div>
        </Wrapper>
    )
}

export default Site;