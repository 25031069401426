import React from 'react'
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { BtnClose, ButtonDiv, CheckBox, Header, OverLay, SelectedList, SiteAddr, SiteInfo, SiteName, Wrapper } from './styles';
import CartItem from './CartItem';

const CartGoods = ({ idCartGoods }) => {
    const cart = useSelector(state => state.cart);


    return (
        <>
            <CheckBox id={idCartGoods} hidden />
            <OverLay htmlFor={idCartGoods} />
            <Wrapper>
                <Header>
                    <SiteInfo>
                        <SiteName>{cart?.siteName || '未入力'}</SiteName>
                        <SiteAddr>{cart?.siteAddr || '―'}</SiteAddr>
                    </SiteInfo>
                    <BtnClose htmlFor={idCartGoods} ><FontAwesomeIcon icon={faXmark} /></BtnClose>
                </Header>
                <SelectedList>
                    {cart.selectedList?.length > 0 ? (cart.selectedList.map((item) => {
                        return (<CartItem key={`${item.goods.GoodsID}_${item.goods.GoodsSubID}_${item.goods.GoodsName}`} item={item} />)
                    })) : <></>}
                    {cart.selectedList?.length > 0 ? (
                        <ButtonDiv>
                            <Link className='ButtonConfirm' to={'/cart'}>注文に進む</Link>
                        </ButtonDiv>
                    ) : (<></>)}
                </SelectedList>
            </Wrapper>
        </>
    )
}


CartGoods.propTypes = {
    idCartGoods: PropTypes.string,
}

CartGoods.defaultProps = {
    idCartGoods: 'id_Cart',
}

export default CartGoods;