import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Line, MLabel, Title, Wrapper } from './styles';
import Common from 'Common';
import DialogAddGoods from 'components/DialogAddGoods';

const SalesGoodsItem = ({ item, priceVisible }) => {
    const [goods, setGoods] = useState({});

    return (
        <Wrapper>
            <Title>
                <Line><MLabel>品名：</MLabel>{item.GoodsName} ｜{item.GoodsSize}</Line>
            </Title>
            <Line>
                <MLabel>数量：</MLabel>{item.Number}
                {!!priceVisible && (<><MLabel className='pl-30'>単価：</MLabel>{Common.NumFormat(item.Price)}</>)}
            </Line>
            <Line>
                <MLabel>受注日：</MLabel>{item.AcceptedDate && item.AcceptedDate.substring(0, 10)}
                <MLabel className='pl-30'>売上日：</MLabel>{item.SalesDate && item.SalesDate.substring(0, 10)}
            </Line>
            <Line>
                <MLabel>メモ：</MLabel>{item.SalesSlipMemo}
            </Line>
            <Line className='align-right'>
                <Button onClick={() => setGoods({ ...item })}>選択</Button>
            </Line>
            <DialogAddGoods goods={goods} handleClose={() => { setGoods({}) }} />
        </Wrapper>
    )
}


SalesGoodsItem.propTypes = {
    item: PropTypes.object.isRequired,
}

SalesGoodsItem.defaultProps = {
}

export default SalesGoodsItem
