import React from "react";
import { PropTypes } from "prop-types";
import { Button, Content, Line, MLabel, Title, Wrapper, GoodsImg } from './styles';

import GoodsImgDefault from "assets/images/goods_default.jpg";
import Common from "Common";

const GoodsItem = ({ item, handleAddToCard }) => {
    return (
        <Wrapper>
            <div><GoodsImg src={item?.itemImageUrl ? item.GoodsImageUrl : GoodsImgDefault} alt="image" /></div>
            <Content>
                <Title>{item.GoodsName || ''}</Title>
                <Line>
                    <MLabel>サイズ：</MLabel>
                    {item.GoodsSize || ''}
                </Line>
                <Line>
                    <MLabel>数量：</MLabel>
                    {item.Number || ''}
                    <MLabel className="ml-30">価格：</MLabel>
                    {Common.NumFormat(item.Price || '0')}
                </Line>
            </Content>
            <Button style={{ minWidth: "50px", position: "absolute", bottom: 4, right: 8 }} onClick={(e) => { handleAddToCard(); }}>再注文</Button>
        </Wrapper>
    )
}

GoodsItem.propTypes = {
    item: PropTypes.object.isRequired,
}

GoodsItem.defaultProps = {
}


export default GoodsItem;