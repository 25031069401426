import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Button,
    Content,
    Favorite,
    GoodsImg,
    Line,
    MLabel,
    RightControl,
    Title,
    Wrapper,
    WrapperContent,
} from './styles'

import GoodsImgDefault from "assets/images/goods_default.jpg";

const numFormat = (num) => {
    return new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 2 }).format(num);
}

const GoodsItem = ({ goods, handleSelect, handleChangeFavorite, handleAddToCart }) => {

    return (
        <Wrapper >
            <WrapperContent onClick={() => { handleSelect(goods.GoodsID, goods.GoodsSubID); }} style={{ pointerEvents: goods?.GoodsParentName ? "none" : "auto" }}>
                {goods?.GoodsParentName ? (<></>) : (
                    <div>
                        <GoodsImg src={goods?.GoodsImageUrl ? goods?.GoodsImageUrl : GoodsImgDefault} alt="image" />
                    </div>)}
                <Content>
                    <Title>{goods.GoodsName || '-'}</Title>
                    <Line>
                        <MLabel>サイズ：</MLabel>
                        {goods.GoodsSize || '―'}
                    </Line>
                    {/* {goods.VisiblePaintingInfoInApp > 0 ? (
                        <>
                            <Line>
                                <MLabel>塗布量：</MLabel>
                                {numFormat(goods.TohuryouMin) + '～' + numFormat(goods.TohuryouMax) + ' 標準：' + numFormat(goods.TohuryouStandard) + ' kg/㎡/回'}
                            </Line>
                            <Line>
                                <MLabel>メモ：</MLabel>
                                {goods.TohuryouMemo || '―'}
                            </Line>
                            <Line>
                                <MLabel>希釈：</MLabel>
                                {goods.DilutionSolution} | {goods.DilutionPercent}
                            </Line>
                        </>
                    ) : (<></>)} */}
                </Content>
            </WrapperContent>
            <RightControl>
                {!goods.GoodsParentName && (
                    <Favorite isFavorite={goods.FavoriteID > 0}
                        onClick={() => { handleChangeFavorite(goods.GoodsID, goods.GoodsSubID || 1, goods.FavoriteID) }}>
                        <FontAwesomeIcon icon={faStar} />
                    </Favorite>
                )}
                <Button onClick={() => { handleAddToCart(goods); }}>選択</Button>
            </RightControl>
        </Wrapper>
    )
}

export default GoodsItem;