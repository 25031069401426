import { TextBox } from "components/TextBox"
import { IroWrapper, FormSearchSub, FavoriteWrapper } from "./styles"
import { useState } from "react"

export const TermGoods = ({ setTermSearch, all }) => {

  const [txtIro, setTxtIro] = useState("");
  const [isFavorite, setIsFavorite] = useState(all ? 0 : 1);
  const [colorLevel, setColorLevel] = useState(false);

  const onHandleSubmit = (e) => {
    if (e.target.length > 0) e.target[0].blur();
    e.preventDefault();
    setTermSearch((preValue) => ({ ...preValue, txtIro, isFavorite }));
  }

  const onHandleClear = () => {
    setTxtIro("");
    setTermSearch((preValue) => ({ ...preValue, txtIro: "" }));

  }

  const onHandleFavoriteChange = (e) => {
    const num = Number(e.target.value || 0);
    setIsFavorite(num);
    setTermSearch((preValue) => ({ ...preValue, isFavorite: num }));
  }

  const onHandleColorLevel = (e) => {
    const isChecked = e.target.checked;
    setColorLevel(isChecked);
    setTermSearch((preValue) => ({ ...preValue, colorLevel: isChecked }));
  }

  return <>
    <FormSearchSub onSubmit={onHandleSubmit}>
      <IroWrapper>
        <label htmlFor="id_iro" style={{ paddingTop: "4px" }}>色Ｎo：</label>
        <div style={{ border: "solid 1px #d3d3d3d3", borderRadius: "8px" }}>
          <TextBox
            id="id_iro"
            enterKeyHint='search'
            placeholder={"色Ｎｏ"}
            maxLength={30}
            value={txtIro}
            onChange={(e) => { setTxtIro(e.target.value) }}
            onClear={onHandleClear}
          />
        </div>
      </IroWrapper>
    </FormSearchSub>
    <FavoriteWrapper>
      <div>
        <input type="radio" id="all-id" name="search-type"
          value={0}
          checked={!isFavorite}
          onChange={onHandleFavoriteChange}
        />
        <label htmlFor="all-id">全て</label>
        <input type="radio" id="favorite-id" name="search-type"
          value={1}
          checked={!!isFavorite}
          onChange={onHandleFavoriteChange} />
        <label htmlFor="favorite-id">お気に入りのみ</label>
      </div>
      <div>
        <input type="checkbox" id="color-level" name="search-type"
          value={1}
          checked={colorLevel}
          onChange={onHandleColorLevel} />
        <label htmlFor="color-level">色見本帳から選択</label>
      </div>

    </FavoriteWrapper>
  </>
}
