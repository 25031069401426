import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addGoods, removeGoods } from '../../../features/Cart/cartSlice';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, Content, Title, Quantity, Number, TitleChild, BtnUpDown, BtnDelete } from './styles';

const CartItem = ({ item }) => {

    const dispatch = useDispatch();

    const handleUpDown = (num) => {
        const selectedGoods = {
            goods: { ...item.goods },
            quantity: num
        }
        dispatch(addGoods(selectedGoods));
    }

    const handleRemoveGoods = () => {
        const selectedGoods = {
            goods: { ...item.goods },
            quantity: item.quantity
        }
        dispatch(removeGoods(selectedGoods));
    }

    return (
        <Wrapper>
            <Title>
                {`${item.goods.GoodsName} ${item.goods.ColorNoName || ''}`}
                <TitleChild>　¦ {item.goods.GoodsSize}</TitleChild>
            </Title>
            <Content>
                <Quantity>
                    <BtnUpDown onClick={() => { if (item.quantity > 1) handleUpDown(-1); }} ><FontAwesomeIcon icon={faMinus} /></BtnUpDown>
                    <Number>{item.quantity}</Number>
                    <BtnUpDown onClick={() => { handleUpDown(+1); }}><FontAwesomeIcon icon={faPlus} /></BtnUpDown>
                </Quantity>
                <BtnDelete onClick={() => { handleRemoveGoods() }}>削除</BtnDelete>
            </Content>
        </Wrapper>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default CartItem