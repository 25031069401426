import Common from "./Common"

const Auth = {
    isLogin: () => {
        const { cUserName, cToken } = Common.localStorageGetToken();
        if (!cUserName || !cToken) return false;
        return true;
    }
}

export default Auth
