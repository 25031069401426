import axiosClient from "./axiosClient";

const colorNoApi = {
    getColorNo(params) {
        const url = '/color-no';
        return axiosClient.get(url, { params: params });
    },
}

export default colorNoApi;
