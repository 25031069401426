import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
padding: 15px 30px;

button{
    padding: 5px 20px;
    font-size: 1rem;
    font-weight: bold;
}

.back {
    background-color: #7c7c7c;
    border-color: #7c7c7c;
}
`;

export const Button = styled.button`
    margin-left:3px;
    padding: 2px 10px;
    font-size: 1rem;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 5px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`;