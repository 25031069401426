import React, { useEffect, useRef, useState } from "react";
import DialogAddGoods from "components/DialogAddGoods";
import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import OrderItem from "./OrderItem";
import { Wrapper, Content, DataEmpty } from './styles'
import { useOrder } from "./useOrder";
import SearchBox from "components/SearchBox";
import GoodsDetails from "features/Goods/GoodsDetails";
import { useGoodsDetails } from "./useGoodsDetails";
import { TermOrder } from "components/TermSearch";
import OrderDetails from "./OrderDetails";

const Order = () => {
    const [goodSelected, setGoodSelected] = useState(null);
    const { setTermSearch, orderList, objOrder, setObjOrder, setOrderSelected, isLoading } = useOrder();
    const { isLoading: isLoading1,
        goods,
        setGoodsID,
        setGoodsSubID,
        setFavoriteID,
        handleBack: handleBackToOrderList } = useGoodsDetails();

    const refTop = useRef();

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [orderList, refTop]);

    const handleAddToCard = (orderGoods) => {
        setGoodSelected({
            GoodsID: orderGoods.GoodsID,
            GoodsSubID: orderGoods.GoodsSubID,
            GoodsSize: orderGoods.GoodsSize,
            GoodsName: orderGoods.GoodsName,
            GoodsKana: orderGoods.GoodsKana,
        })
    }
    const handleSelectGoods = (goodsID, goodsSubID) => {
        setGoodsID(goodsID);
        setGoodsSubID(goodsSubID);
    }
    const handleClick = (orderID, slipID) => {
        setOrderSelected({ orderID, slipID });
    }
    const handleBack = () => {
        setObjOrder(null);
        setGoodSelected(null);
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading || isLoading1} />
            <HeaderBox title={goods?.GoodsID > 0 ? "商品明細" : "注文履歴"} haveCart={true} />
            <div style={{ position: "relative" }}>
                {/* <SearchBox termBoxType={Common.TERMBOX_ORDER} searchTerm={termSearch} setSearchTerm={setTermSearch} textHolder={'商品名'} /> */}
                <SearchBox setTermSearch={setTermSearch} placeholder={'商品名'} >
                    <TermOrder setTermSearch={setTermSearch} />
                </SearchBox>
                <Content ref={refTop}>
                    {orderList.length > 0 ?
                        (orderList.map((item) => {
                            return (<OrderItem key={item.OrderGoodsID + "" + item.SalesGoodsID}
                                item={item}
                                handleAddToCard={handleAddToCard}
                                handleSelectGoods={handleSelectGoods}
                                handleClick={handleClick} />)
                        }))
                        : (<DataEmpty>データなし</DataEmpty>)}
                </Content>
                {goods?.GoodsID > 0 && (<GoodsDetails goods={goods}
                    handleChangeFavorite={() => { setFavoriteID(goods?.FavoriteID || 1) }}
                    handleAddToCart={() => { setGoodSelected({ ...goods }) }}
                    handleBack={handleBackToOrderList} />)}
                {!!objOrder?.length && (<OrderDetails order={objOrder}
                    handleAddToCard={handleAddToCard} handleBack={handleBack} />)}
            </div>
            <DialogAddGoods goods={goodSelected} handleClose={() => { setGoodSelected(null) }} />
        </Wrapper>
    )
}

export default Order;