import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
export const Content = styled.div`
`;

export const InputText = styled.input.attrs({ type: 'text' })`
    width: 70%;
    max-width:350px;
    padding: 2px 5px;
    font-size: 1rem;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid #6c6c6c;
`;

export const ButtonInput = styled.button`
    margin-left:3px;
    padding: 2px 10px;
    font-size: 1rem;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 5px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`
export const FormSearch = styled.div`
    display: ${props => props.show ? 'flex' : 'none'} ;
    justify-content: center;
    position:fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0px;
    background-color: rgba(230,230,230, 0.5);
    z-index: 10; 
`;

export const FormBox = styled.div`
    width: 95%;
    max-width: 400px;
    max-height: 500px;
    margin-top: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
`;

export const FormHeader = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: white;
    padding: 3px 10px;
    background-color: var(--morienGreen);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

`;

export const FormContent = styled.div`
    flex: 1;
    flex-grow:1;
    overflow-y:scroll;
    max-height: 70%;
    background-color: white;
`;
export const FormFooter = styled.div`
    padding: 5px 10px 10px 10px;
    text-align: right;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #e6e6e6;    
`;
export const ButtomClose = styled.button`
    padding: 3px 10px;
    font-size: 1rem;
    border:1px solid transparent;
    border-color:  #808080;
    border-radius: 5px;
    background-color: #808080;
    color: white;    
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`;