import axiosClient from "./axiosClient";

const colorBookApi = {
    getAllColorBook() {
        const url = '/color-book';
        return axiosClient.get(url);
    },
}

export default colorBookApi;
