import { useEffect, useState } from "react";
import siteApi from "api/siteApi";


export const useSiteDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  //site details
  const [siteID, setSiteID] = useState(0);
  const [objSite, setObjSite] = useState({});
  const [salesGoodsList, setSalesGoodsList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  //get site details
  useEffect(() => {
    (async function getSiteDetails() {
      if (siteID < 1) return;
      try {
        setIsLoading(true);
        /**Call Api */
        const params = {
          siteID: siteID || ''
        }
        const dtResponse = await siteApi.getSiteDetails(params);
        const site = dtResponse?.data['site'] ?? {};
        const salesGoods = dtResponse?.data['salesGoods'] ?? [];
        let totalAmount = 0;
        if (!!site.PriceVisible && salesGoods.length > 0) {
          totalAmount = salesGoods.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.TotalPrice || 0), 0);
        }
        setTotalPrice(totalAmount);
        setObjSite(site);
        setSalesGoodsList(salesGoods);
      } catch (err) {
        alert(err);
      }
      finally {
        setIsLoading(false);
      }
    })();
  }, [siteID]);


  const handleSelectSite = (_siteID) => { setSiteID(_siteID); }
  const handleBackSiteList = () => {
    setSiteID(0);
    setObjSite({});
    setSalesGoodsList([]);
    setTotalPrice(0);
  }
  return { isLoading, handleSelectSite, handleBackSiteList, objSite, salesGoodsList, totalPrice };

}