import { useEffect, useRef, useState } from "react";
import goodsApi from "api/goodsApi";



export const useGoods = (all) => {
  const [termSearch, setTermSearch] = useState({ isFavorite: all ? 0 : 1 });
  const [goodsList, setGoodsList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [goods, setGoods] = useState();
  const [goodsAdd, setGoodsAdd] = useState();

  const refTop = useRef();
  const title = !!goods?.GoodsID ? "商品明細" : "商品検索";


  // Select Goods -> show Goods Details
  const hanleSelectGoods = async (goodsID, goodsSubID) => {
    if (!goodsID) return;

    try {
      setIsLoading(true);
      const { data } = await goodsApi.getGoodsDetails({ id: goodsID, goodsSubID });
      setGoods({ ...data.dtGoodsInfo[0] });
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  }

  // Change  Favorite
  const handleChangeFavorite = async (goodsID, goodsSubID, favoriteID) => {
    try {
      setIsLoading(true);
      /**Call Api */
      const { data } = await goodsApi.updateGoodsFavorite({
        goodsID: goodsID,
        goodsSubID: goodsSubID,
        favoriteID: favoriteID || '',
      });
      const params = {
        goodsName: termSearch?.txtSearch || '',
        iro: termSearch?.txtIro || '',
        favorite: termSearch?.isFavorite === undefined ? 1 : termSearch?.isFavorite,
        colorLevel: !!termSearch?.colorLevel,
      }
      const dtResponse = await goodsApi.searchGoods(params);
      const dtGoods = dtResponse?.data['dtGoods'] ?? [];
      setGoodsList([...dtGoods]);
      setGoods((preValue) => ({ ...preValue, FavoriteID: data.favoriteID }));

    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  }

  //Add Goods to Cart
  const handleAddToCart = (_goodsAdd) => {
    setGoodsAdd({ ..._goodsAdd })
  }
  const handleCloseAddToCart = (_goodsAdd) => {
    setGoodsAdd(undefined)
  }

  // Back from Goods Detials
  const handleBack = () => {
    setGoods(null);
  }

  //Search Goods
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          goodsName: termSearch?.txtSearch || '',
          iro: termSearch?.txtIro || '',
          favorite: termSearch?.isFavorite === undefined ? 1 : termSearch?.isFavorite,
          colorLevel: !!termSearch?.colorLevel,
        }

        const dtResponse = await goodsApi.searchGoods(params);
        const dtGoods = dtResponse?.data['dtGoods'] ?? [];
        setGoodsList([...dtGoods]);
        if (refTop) refTop.current.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [termSearch])


  // Return data 
  return {
    title,
    refTop,
    termSearch,
    setTermSearch,
    goodsList,
    isLoading,
    goods,
    goodsAdd,
    hanleSelectGoods,
    handleChangeFavorite,
    handleAddToCart,
    handleCloseAddToCart,
    handleBack,
  }
}