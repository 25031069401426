import styled from "styled-components"

export const MLabel = styled.label`
    font-size: 0.9rem;
    color: #6c757d;
`
export const Title = styled.span`
    color:var(--morienGreen);
    font-size: 1rem;
    font-weight:bold;
`

export const Line = styled.div`
`