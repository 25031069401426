import React, { useEffect, useState } from "react";
import Compressor from "compressorjs";
import { useNavigate } from "react-router-dom";
import { Button, Content, Footer, InputImg, InputNumber, InputText, MLabel, Row, Wrapper } from "./styles";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import imgPostApi from "api/imgPostApi";


const MaxAllowedSize = 5 * 1024 * 1024; // 5MB

const ImgPost = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [flgUpload, setFlgUpload] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [addr, setAddr] = useState("");
    const [imgHouse, setImgHouse] = useState();
    const [imgBoard, setImgBoard] = useState();
    const [imgBoardDetail, setimgBoardDetail] = useState();
    const [oldNum, setOldNum] = useState(0);
    const navigate = useNavigate();

    const handleCapture = (target, setImgSrc) => {
        console.log("target:", target.files);
        if (target.files) {
            if (target.files.length !== 0) {
                const qualityNum = target.files[0].size > MaxAllowedSize ? 0.7 : 1;
                new Compressor(target.files[0], {
                    quality: qualityNum,
                    convertTypes: ['image/png', 'image/webp', 'image/jpeg'],
                    convertSize: 5000000,
                    success(result) {
                        const reader = new FileReader();
                        reader.readAsDataURL(result);
                        reader.onload = () => {
                            if (reader.result) setImgSrc(reader.result.toString());
                        };
                    },
                });
            }
        }
    };

    useEffect(() => {
        (async function uploadImg() {
            if (!flgUpload) return;
            //validate
            var msg = "";
            if (!imgHouse) {
                msg = "家の全景写真を選択してください";
            } else if (!imgBoard) {
                msg = "ボード全体写真を選択してください";
            } else if (!imgBoardDetail) {
                msg = "ボード接写写真を選択してください";
            }
            if (msg) {
                alert(msg);
                setFlgUpload(false);
                return;
            }
            try {
                setIsLoading(true);
                const params = {
                    siteName,
                    addr,
                    imgHouse,
                    imgBoard,
                    imgBoardDetail,
                    oldNum
                }
                const dtResponse = await imgPostApi.post(params);
                const status = dtResponse?.data['status'] ?? '';
                if (!status) {
                    throw new Error("投稿に失敗しました");
                }
                alert("成功しました。");
                navigate('/', { replace: true });

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
                setFlgUpload(false);
            }
        })()
    }, [flgUpload])

    return <Wrapper>
        <Loading isLoading={isLoading} />
        <HeaderBox title="写真投稿" haveCart={false} />
        <Content>
            <Row column>
                <MLabel>現場名：</MLabel>
                <InputText
                    maxLength={100}
                    textHolder={'現場名：'}
                    value={siteName}
                    onChange={(e) => { setSiteName(e.target.value) }}
                />
            </Row>
            <Row column>
                <MLabel>住所：</MLabel>
                <InputText
                    maxLength={100}
                    textHolder={'住所：'}
                    value={addr}
                    onChange={(e) => { setAddr(e.target.value) }}
                />
            </Row>
            <Row column>
                <MLabel>家の全景写真：</MLabel>
                <InputImg onChange={(e) => {
                    handleCapture(e.target, setImgHouse);
                }} />
            </Row>
            <Row column>
                <MLabel>ボード全体写真：</MLabel>
                <InputImg onChange={(e) => {
                    handleCapture(e.target, setImgBoard);
                }} />
            </Row>
            <Row column>
                <MLabel>ボード接写写真：</MLabel>
                <InputImg onChange={(e) => {
                    handleCapture(e.target, setimgBoardDetail);
                }} />
            </Row>
            <Row column>
                <MLabel>築年数：</MLabel>
                <InputNumber
                    width={'100px'}
                    maxLength={10}
                    value={oldNum}
                    onChange={(e) => { setOldNum(e.target.value) }}
                />
            </Row>
            <Footer>
                <Button onClick={() => { setFlgUpload(true) }}>投稿</Button>
            </Footer>
        </Content>

    </Wrapper>
};

export default ImgPost;