import { createSlice } from '@reduxjs/toolkit'

const initialState = {} // site Object

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        select: (state, actions) => {

        }
    },
})

// Action creators are generated for each case reducer function
export const { select } = siteSlice.actions;

export default siteSlice.reducer