import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 3px;
    padding: 3px 5px;
    border-bottom: 1px solid lightgray;
`;

export const Title = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

export const TitleChild = styled.span`
    font-weight: normal;
    color: gray;   

`;

export const Content = styled.div`
    display: flex;
    margin-top: 10px;
`;
export const Quantity = styled.div`
    display: flex;
    flex: 1;
    margin-left: 30px;
    align-items: center;
`;
export const Number = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 10px;
    text-align: center;
`;

export const BtnUpDown = styled.button`
    appearance: none;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 3px 10px;
    border: 2px solid transparent;
    cursor: pointer;
    background-color: rgba(112, 76, 182, 0.1);
    border-radius: 5px;
    color: blue;
`;

export const BtnDelete = styled.button`
    appearance: none;
    font-size: 1rem;
    text-align: center;
    border: none;
    padding: 3px 7px;
    outline: none;
    border: 2px solid transparent;
    cursor: pointer;
    color: rgba(255, 0,0);
    background-color: rgba(215, 219, 221, 0.7);
    border-radius: 5px;
    margin-right: 8px;
`;