import styled from 'styled-components';


export const Wrapper = styled.div`
    position: absolute;
    left: 12px;
    bottom: 150px;
    z-index: 3;
    padding: 12px 14px;
    border: 1px solid rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    color: #fff;
    background-color: rgba(0, 149, 39, 0.8);
    font-size: 1.2rem;
    cursor: pointer;
    :hover {
        transform: scale(1.2);
        color: #000;
        background-color: rgba(0, 149, 39, 1);

    }
`
