import React, { useState } from "react";
import Loading from "../../components/Loading";
import { useLogin } from "./useLogin";
import ImgLogo from '../../assets/images/logo.png'
import { Wrapper, Content, Logo } from './styles'

const Login = () => {
    const { setLoginInfo, isLoading } = useLogin();
    const [userName, setUserName] = useState('');
    const [uPassword, setUPassword] = useState('');

    //Validate User Info
    const validateInfo = () => {
        if (!userName || !uPassword) {
            //TODO: show dialog
            alert('ユーザー名またはパスワードは空ですから、入力してください。');
            return false;
        }
        return true;
    }

    //Submit User Info
    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (validateInfo()) {
            setLoginInfo({
                userName: userName,
                password: uPassword
            })
        }
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <Content onSubmit={handleOnSubmit}>
                <Logo src={ImgLogo} alt='Logo' />
                <input type='text'
                    placeholder='ユーザー名'
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
                <input type='password'
                    placeholder='パスワード'
                    autoComplete='on'
                    value={uPassword}
                    onChange={(e) => setUPassword(e.target.value)}
                />
                <button type='submit'>ログイン</button>
            </Content>
        </Wrapper>
    );
};

export default Login;
