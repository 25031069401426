import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { ButtomClose, ButtonInput, Content, FormBox, FormContent, FormFooter, FormHeader, FormSearch, InputText, Wrapper } from './styles'
import ItemSelect from '../ItemSelect';
import SearchBox from 'components/SearchBox';

const InputSelectBox = ({ title, name, textHolder, termSearch, setTermSearch, termBoxType, resultList, renderItem, selectItem, classNameCss }) => {
    const [show, setShow] = useState(false);
    const refTop = createRef();

    const handleSelect = (item) => {
        selectItem(item);
        setShow(false);
    }

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [resultList, refTop])

    return (
        <Wrapper className={classNameCss}>
            <Content>
                <InputText
                    readOnly
                    value={name}
                />
                <ButtonInput onClick={() => { setTermSearch({ ...termSearch }); setShow(true); }}>入力</ButtonInput>
            </Content>
            <FormSearch show={show}>
                <FormBox>
                    <FormHeader>
                        {title}
                    </FormHeader>
                    {/* <SearchBox textHolder={textHolder} searchTerm={termSearch} setSearchTerm={setTermSearch} termBoxType={termBoxType} /> */}
                    <SearchBox placeholder={textHolder} setTermSearch={setTermSearch} />
                    <FormContent ref={refTop}>
                        {resultList?.length > 0 ? (
                            <>
                                {resultList.map((item, index) => <ItemSelect key={index} handleSelect={() => { handleSelect(item); }}>{renderItem(item)}</ItemSelect>)}
                            </>
                        ) : (<>データなし</>)}
                    </FormContent>
                    <FormFooter>
                        <ButtomClose onClick={() => setShow(false)}>閉じる</ButtomClose>
                    </FormFooter>
                </FormBox>
            </FormSearch>
        </Wrapper >
    )
}

InputSelectBox.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
}

InputSelectBox.defaultProps = {
    title: '',
    name: '',
}

export default InputSelectBox