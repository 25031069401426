import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addGoods } from "features/Cart/cartSlice";
import {
  BtnClose, BtnQuantity, ButtonSelect, Dialog
  , DialogContent, DialogTitle, Line, MLabel, Overlay, Quantity
  , TextContent
  , TextTile, WrapperQuantity
} from './styles'
import SelectColor from "./SelectColor";


const DialogAddGoods = ({ goods, handleClose }) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const showSelectColor = !!goods?.GoodsName ? goods.GoodsName.trim().endsWith("調色") : false;
  const [colorBook, setColorBook] = useState({
    selectedValue: {},
    options: []
  });
  const [colorNo, setColorNo] = useState({
    selectedValue: {},
    options: []
  });
  const handleSelectGoods = () => {
    //色見本帳選択
    let colorInfo = {};
    if (!showSelectColor) {
      colorInfo = {};
    } else {
      const selectedColorBook = colorBook.selectedValue;
      const selectedColorNo = colorNo.selectedValue;
      colorInfo = {
        ColorBookID: selectedColorBook.ColorBookID,
        ColorNoID: selectedColorNo.ColorNoID,
        ColorLevelID: selectedColorNo.ColorLevelID,
        ColorNoName: selectedColorNo.ColorNoName
      }
    }
    //select goods to cart
    const selectedGoods = {
      goods: { ...goods, GoodsSubID: goods.GoodsSubID || 0, GoodsName: `${goods.GoodsName}`.trim(), GoodsKana: goods.GoodsKana || goods.GoodsKanaReal || '', ...colorInfo },
      quantity: quantity
    }
    dispatch(addGoods(selectedGoods));
    handleDialogClose();
  }

  const handleDialogClose = () => {
    setQuantity(1);
    handleClose();
  }


  return <>
    {goods?.GoodsID && (<>
      <Dialog>
        <DialogTitle>
          <TextTile>数量選択</TextTile>
          <BtnClose onClick={() => { handleDialogClose(); }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
        </DialogTitle>
        <DialogContent>
          <TextContent>
            <Line>
              <MLabel>商品：</MLabel>{goods.GoodsName || '―'}
            </Line>
            <Line>
              <MLabel>サイズ：</MLabel>{goods.GoodsSize || '-'}
            </Line>
            {!!showSelectColor && (<SelectColor colorBook={colorBook} setColorBook={setColorBook} colorNo={colorNo} setColorNo={setColorNo} />)}
          </TextContent>
          <WrapperQuantity>
            <BtnQuantity onClick={() => { if (quantity > 1) setQuantity(prevValue => (prevValue - 1)) }}><FontAwesomeIcon icon={faMinus} /></BtnQuantity>
            <Quantity>{quantity}</Quantity>
            <BtnQuantity onClick={() => setQuantity(prevValue => (prevValue + 1))} ><FontAwesomeIcon icon={faPlus} /></BtnQuantity>
          </WrapperQuantity>
          <ButtonSelect onClick={() => { handleSelectGoods(); }} >選択</ButtonSelect>
        </DialogContent>
      </Dialog>
      <Overlay /></>)}
  </>;
};

export default DialogAddGoods
