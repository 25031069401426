import React from "react";
import { useGoods, useMorienShopSearch } from "./useMorienShopSearch";
import { Content, HeaderTitle, Wrapper } from "./styles";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import SearchBox from "components/SearchBox";
import { TermGoods } from "components/TermSearch";
import ShopGoodsItem from "./ShopGoodsItem";

export const MorienShopSearch = () => {
  const {
    refTop,
    setTermSearch,
    goodsList,
    isLoading,
  } = useMorienShopSearch();

  return (
    <Wrapper>
      <Loading isLoading={isLoading} />
      <HeaderTitle>
        {"商品検索"}
      </HeaderTitle>
      <div style={{ position: "relative" }}>
        <SearchBox setTermSearch={setTermSearch} placeholder="商品名">
        </SearchBox>
        <Content ref={refTop}>
          {!!goodsList?.length && (
            goodsList.map((goods, index) =>
              <ShopGoodsItem key={`${index}${(new Date()).getTime()}_${goods.GoodsID}_${goods.GoodsSubID}`}
                goods={goods}
              />
            ))}
        </Content>
      </div>
    </Wrapper>);
};
